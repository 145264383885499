import React from 'react';

interface ContactLinkProps {
  type: 'email' | 'phone';
  value: string;
  className?: string;
  children?: React.ReactNode;
}

export function ContactLink({ type, value, className = '', children }: ContactLinkProps) {
  const getHref = () => {
    if (type === 'email') {
      return `mailto:${value}`;
    }
    // Remove any non-digit characters for WhatsApp
    const cleanNumber = value.replace(/\D/g, '');
    return `https://wa.me/${cleanNumber}`;
  };

  return (
    <a
      href={getHref()}
      className={className}
      target={type === 'phone' ? '_blank' : undefined}
      rel={type === 'phone' ? 'noopener noreferrer' : undefined}
    >
      {children || value}
    </a>
  );
}