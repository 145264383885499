import React from 'react';
import { Button } from '../common/Button';
import { ExternalLink } from 'lucide-react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export function LatestWork() {
  const { t } = useTranslation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1
  });

  return (
    <div className="py-24 bg-gray-50" ref={ref}>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={inView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 0.6 }}
          className="text-center"
        >
          <h2 className="text-3xl font-bold text-gray-900 sm:text-4xl">
            {t('latestWork.title')}
          </h2>
          <p className="mt-4 text-lg text-gray-600 max-w-2xl mx-auto">
            {t('latestWork.subtitle')}
          </p>
        </motion.div>

        <div className="mt-20 grid grid-cols-1 gap-12 md:grid-cols-2">
          {[
            {
              title: t('latestWork.projects.oudriss.title'),
              description: t('latestWork.projects.oudriss.description'),
              image: 'https://images.unsplash.com/photo-1503376780353-7e6692767b70?auto=format&fit=crop&q=80',
              category: t('latestWork.projects.oudriss.category')
            },
            {
              title: t('latestWork.projects.nextDigital.title'),
              description: t('latestWork.projects.nextDigital.description'),
              image: 'https://images.unsplash.com/photo-1460925895917-afdab827c52f?auto=format&fit=crop&q=80',
              category: t('latestWork.projects.nextDigital.category')
            }
          ].map((project, index) => (
            <Link to="/portfolio" key={index}>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={inView ? { opacity: 1, y: 0 } : {}}
                transition={{ duration: 0.6, delay: index * 0.2 }}
                className="group relative"
              >
                <div className="relative h-80 w-full overflow-hidden rounded-lg bg-white group-hover:shadow-xl transition-shadow duration-300">
                  <img
                    src={project.image}
                    alt={project.title}
                    className="h-full w-full object-cover object-center transition-transform duration-300 group-hover:scale-105"
                  />
                  <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent" />
                  <div className="absolute bottom-0 left-0 right-0 p-6 text-white">
                    <div className="flex items-center justify-between">
                      <div>
                        <p className="text-sm font-medium">{project.category}</p>
                        <h3 className="mt-2 text-xl font-semibold">{project.title}</h3>
                        <p className="mt-2 text-sm text-gray-200">{project.description}</p>
                      </div>
                      <ExternalLink className="h-6 w-6 opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                    </div>
                  </div>
                </div>
              </motion.div>
            </Link>
          ))}
        </div>

        <div className="mt-12 text-center">
          <Button href="/portfolio" variant="outline">
            {t('latestWork.viewAll')}
          </Button>
        </div>
      </div>
    </div>
  );
}