import React, { useRef } from 'react';
import { ArrowRight } from 'lucide-react';
import { motion, useScroll, useTransform } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

// Preload hero image
const HERO_IMAGE_URL = 'https://images.unsplash.com/photo-1522071820081-009f0129c71c?auto=format&fit=crop&q=80&w=2000';
const preloadImage = new Image();
preloadImage.src = HERO_IMAGE_URL;

export function Hero() {
  const { t } = useTranslation();
  const containerRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: containerRef,
    offset: ["start start", "end start"]
  });

  const opacity = useTransform(scrollYProgress, [0, 0.5], [1, 0]);
  const scale = useTransform(scrollYProgress, [0, 0.5], [1, 0.8]);
  const y = useTransform(scrollYProgress, [0, 0.5], [0, 100]);

  return (
    <div ref={containerRef} className="relative min-h-screen flex items-center pt-24">
      <motion.div
        className="absolute inset-0 z-0 bg-cover bg-center"
        initial={{ scale: 1 }}
        animate={{ scale: 1 }}
        style={{
          backgroundImage: `url(${HERO_IMAGE_URL})`,
          willChange: 'transform'
        }}
      >
        <motion.div 
          className="absolute inset-0 bg-black/40 backdrop-blur-sm"
          initial={{ opacity: 1 }}
          animate={{ opacity: 1 }}
        />
      </motion.div>
      
      <motion.div 
        className="relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center"
        style={{ opacity, scale, y }}
      >
        <div className="flex flex-col items-center justify-center space-y-8 md:space-y-12">
          <div className="space-y-6 md:space-y-8">
            <motion.h1
              className="text-4xl md:text-5xl lg:text-6xl font-bold text-white"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
            >
              {t('hero.title1')}
            </motion.h1>
            <motion.h1
              className="text-4xl md:text-5xl lg:text-6xl font-bold text-white"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4 }}
            >
              {t('hero.title2')}
            </motion.h1>
            <motion.h1
              className="text-4xl md:text-5xl lg:text-6xl font-bold text-emerald-400"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.6 }}
            >
              {t('hero.title3')}
            </motion.h1>
          </div>

          <motion.p
            className="text-lg md:text-xl text-gray-200 max-w-2xl mx-auto px-4"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.8 }}
          >
            {t('hero.subtitle')}
          </motion.p>

          <motion.div
            className="flex flex-col sm:flex-row gap-4 justify-center w-full max-w-md mx-auto px-4 mb-16"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 1 }}
          >
            <Link
              to="/contact"
              className="w-full sm:w-auto px-8 py-4 text-base font-medium rounded-full text-black bg-white hover:bg-gray-100 transition-all flex items-center justify-center shadow-lg hover:shadow-xl"
            >
              {t('hero.cta.work')}
              <ArrowRight className="ml-2 h-5 w-5" />
            </Link>
            <Link
              to="/portfolio"
              className="w-full sm:w-auto px-8 py-4 text-base font-medium rounded-full text-white border-2 border-white hover:bg-white/10 transition-all flex items-center justify-center shadow-lg hover:shadow-xl"
            >
              {t('hero.cta.portfolio')}
            </Link>
          </motion.div>
        </div>
      </motion.div>
    </div>
  );
}