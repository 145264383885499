import React from 'react';
import { Link } from 'react-router-dom';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'primary' | 'secondary' | 'outline';
  href?: string;
}

export function Button({ 
  children, 
  variant = 'primary', 
  href,
  className = '',
  ...props 
}: ButtonProps) {
  const baseStyles = 'inline-flex items-center px-6 py-2.5 text-sm font-medium transition-colors duration-200';
  const variants = {
    primary: 'bg-black text-white hover:bg-gray-900',
    secondary: 'bg-white text-black hover:bg-gray-100',
    outline: 'border border-black text-black hover:bg-black hover:text-white',
  };

  const classes = `${baseStyles} ${variants[variant]} ${className}`;

  if (href) {
    return (
      <Link to={href} className={classes}>
        {children}
      </Link>
    );
  }

  return (
    <button className={classes} {...props}>
      {children}
    </button>
  );
}