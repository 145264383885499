import React from 'react';
import { useTranslation } from 'react-i18next';

export function LanguageSwitcher() {
  const { i18n } = useTranslation();

  const languages = [
    { code: 'en', name: 'EN' },
    { code: 'nl', name: 'NL' },
    { code: 'fr', name: 'FR' }
  ];

  return (
    <div className="flex items-center gap-1">
      {languages.map((lang) => (
        <button
          key={lang.code}
          onClick={() => i18n.changeLanguage(lang.code)}
          className={`px-2 py-1 text-xs uppercase font-medium tracking-wider ${
            i18n.language === lang.code
              ? 'bg-white text-black rounded'
              : 'text-white/80 hover:text-white'
          }`}
        >
          {lang.name}
        </button>
      ))}
    </div>
  );
}