import React, { useState, useEffect } from 'react';
import { Menu, X } from 'lucide-react';
import { Link } from 'react-router-dom';
import { motion, useScroll, AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { LanguageSwitcher } from './LanguageSwitcher';

export function Header() {
  const { t } = useTranslation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { scrollY } = useScroll();
  const [isScrolled, setIsScrolled] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [isHovering, setIsHovering] = useState(false);
  let hideTimeout: NodeJS.Timeout;

  const navigation = [
    { name: t('nav.home'), href: '/' },
    { name: t('nav.services'), href: '/services' },
    { name: t('nav.about'), href: '/about' }
  ];

  useEffect(() => {
    return scrollY.onChange((latest) => {
      if (latest <= 50) {
        setIsVisible(true);
        setIsScrolled(false);
        return;
      }
      setIsScrolled(true);
      if (!isHovering) {
        setIsVisible(false);
      }
    });
  }, [scrollY, isHovering]);

  const handleMouseEnter = () => {
    if (hideTimeout) {
      clearTimeout(hideTimeout);
    }
    setIsHovering(true);
    setIsVisible(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
    if (window.scrollY > 50) {
      hideTimeout = setTimeout(() => {
        setIsVisible(false);
      }, 2000);
    }
  };

  return (
    <div 
      className="fixed w-full z-50 px-4 sm:px-6 lg:px-8 pt-4"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <AnimatePresence>
        {(isVisible || isMenuOpen) && (
          <motion.div
            className={`mx-auto max-w-7xl rounded-[2rem] transition-colors duration-200 ${
              isScrolled ? 'bg-black/85 backdrop-blur-md shadow-2xl' : 'bg-black'
            }`}
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.2, ease: "easeOut" }}
          >
            <div className="flex justify-between items-center px-8 py-4">
              <Link to="/" className="font-black text-lg tracking-tighter text-white">
                CSG
              </Link>
              
              <nav className="hidden lg:flex space-x-8 absolute left-1/2 transform -translate-x-1/2">
                {navigation.map((item) => (
                  <Link
                    key={item.name}
                    to={item.href}
                    className="text-sm text-white/80 hover:text-white transition-colors font-medium"
                  >
                    {item.name}
                  </Link>
                ))}
              </nav>

              <div className="hidden lg:flex items-center gap-4">
                <LanguageSwitcher />
                <Link
                  to="/contact"
                  className="inline-flex items-center px-4 py-2 border-2 border-white text-sm font-medium text-white hover:bg-white hover:text-black transition-all duration-200 rounded-full"
                >
                  {t('nav.contact')}
                </Link>
              </div>

              <div className="lg:hidden">
                <button
                  type="button"
                  className="inline-flex items-center justify-center p-2 rounded-full text-white hover:text-gray-200"
                  onClick={() => setIsMenuOpen(!isMenuOpen)}
                >
                  {isMenuOpen ? <X className="h-5 w-5" /> : <Menu className="h-5 w-5" />}
                </button>
              </div>
            </div>

            <AnimatePresence>
              {isMenuOpen && (
                <motion.div 
                  className="lg:hidden"
                  initial={{ opacity: 0, height: 0 }}
                  animate={{ opacity: 1, height: 'auto' }}
                  exit={{ opacity: 0, height: 0 }}
                  transition={{ duration: 0.2 }}
                >
                  <div className="px-6 pt-2 pb-4 space-y-2">
                    {navigation.map((item) => (
                      <Link
                        key={item.name}
                        to={item.href}
                        className="block px-4 py-2 text-sm font-medium text-white/80 hover:text-white rounded-full hover:bg-white/10 transition-all"
                        onClick={() => setIsMenuOpen(false)}
                      >
                        {item.name}
                      </Link>
                    ))}
                    <div className="px-4 py-2">
                      <LanguageSwitcher />
                    </div>
                    <Link
                      to="/contact"
                      className="block px-4 py-2 text-sm font-medium text-black bg-white hover:bg-gray-100 rounded-full mt-4 text-center"
                      onClick={() => setIsMenuOpen(false)}
                    >
                      {t('nav.contact')}
                    </Link>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}