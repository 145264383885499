import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useTranslation } from 'react-i18next';

export function Testimonials() {
  const { t } = useTranslation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1
  });

  const testimonialVariants = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    hover: {
      scale: 1.02,
      transition: { duration: 0.2, ease: "easeOut" }
    }
  };

  const testimonials = [
    {
      content: "Working with Corporate Solutions Group transformed our online presence. Their attention to detail and strategic approach exceeded our expectations.",
      author: "Sarah Johnson",
      role: "CEO, TechStart Inc.",
      image: 'https://images.unsplash.com/photo-1494790108377-be9c29b29330?auto=format&fit=crop&q=80'
    },
    {
      content: "The team's expertise in digital strategy helped us achieve remarkable growth. They're not just service providers; they're true partners in success.",
      author: "Michael Chen",
      role: "Marketing Director, InnovateCo",
      image: 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?auto=format&fit=crop&q=80'
    }
  ];

  return (
    <div className="py-24 bg-white" ref={ref}>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 gap-12 md:grid-cols-2">
          {testimonials.map((testimonial, index) => (
            <motion.div
              key={index}
              variants={testimonialVariants}
              initial="initial"
              animate={inView ? "animate" : "initial"}
              whileHover="hover"
              transition={{ duration: 0.4, delay: index * 0.2 }}
              className="relative rounded-2xl border border-gray-200 p-8 hover:border-gray-300 transition-colors"
            >
              <div className="flex items-start gap-6">
                <img
                  className="h-12 w-12 rounded-full object-cover"
                  src={testimonial.image}
                  alt={testimonial.author}
                />
                <div className="flex-1">
                  <motion.p 
                    className="text-lg text-gray-600 mb-4"
                    whileHover={{ scale: 1.01 }}
                    transition={{ duration: 0.2 }}
                  >
                    {testimonial.content}
                  </motion.p>
                  <motion.div
                    whileHover={{ x: 5 }}
                    transition={{ duration: 0.2 }}
                  >
                    <div className="text-base font-medium text-gray-900">{testimonial.author}</div>
                    <div className="text-sm text-gray-500">{testimonial.role}</div>
                  </motion.div>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
}