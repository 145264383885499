import React from 'react';
import { Hero } from '../components/home/Hero';
import { Features } from '../components/home/Features';
import { Services } from '../components/home/Services';
import { LatestWork } from '../components/home/LatestWork';
import { Testimonials } from '../components/home/Testimonials';
import { CTASection } from '../components/home/CTASection';
import { ImageComparison } from '../components/home/ImageComparison';

export function Home() {
  return (
    <div>
      <Hero />
      <div className="bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="py-24">
            <div className="text-center mb-16">
              <h2 className="text-3xl font-bold text-gray-900 sm:text-4xl mb-4">
                Experience the Difference
              </h2>
              <p className="text-lg text-gray-600">
                See how we transform digital experiences with our expertise
              </p>
            </div>
            <ImageComparison />
          </div>
        </div>
      </div>
      <Features />
      <Services />
      <LatestWork />
      <Testimonials />
      <CTASection />
    </div>
  );
}