import React from 'react';
import { Facebook, Instagram, Linkedin } from 'lucide-react';
import { Link } from 'react-router-dom';
import { ContactLink } from '../common/ContactLink';

interface ScrollToTopLinkProps {
  to: string;
  children: React.ReactNode;
  className?: string;
}

function ScrollToTopLink({ to, children, className }: ScrollToTopLinkProps) {
  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <Link to={to} className={className} onClick={handleClick}>
      {children}
    </Link>
  );
}

export function Footer() {
  return (
    <footer className="bg-black text-white py-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          <div>
            <h3 className="text-lg font-semibold mb-4">Corporate Solutions Group</h3>
            <p className="text-gray-400">
              Empowering businesses with innovative digital solutions.
            </p>
            <p className="text-gray-400 mt-2">
              BTW: BE1016 443 697
            </p>
          </div>
          
          <div>
            <h4 className="text-lg font-semibold mb-4">Quick Links</h4>
            <ul className="space-y-2">
              <li>
                <ScrollToTopLink to="/services" className="text-gray-400 hover:text-white transition-colors">
                  Services
                </ScrollToTopLink>
              </li>
              <li>
                <ScrollToTopLink to="/about" className="text-gray-400 hover:text-white transition-colors">
                  About Us
                </ScrollToTopLink>
              </li>
              <li>
                <ScrollToTopLink to="/contact" className="text-gray-400 hover:text-white transition-colors">
                  Contact
                </ScrollToTopLink>
              </li>
              <li>
                <ScrollToTopLink to="/affiliate" className="text-gray-400 hover:text-white transition-colors">
                  Affiliate Program
                </ScrollToTopLink>
              </li>
            </ul>
          </div>
          
          <div>
            <h4 className="text-lg font-semibold mb-4">Legal</h4>
            <ul className="space-y-2">
              <li>
                <ScrollToTopLink to="/privacy-policy" className="text-gray-400 hover:text-white transition-colors">
                  Privacy Policy
                </ScrollToTopLink>
              </li>
              <li>
                <ScrollToTopLink to="/terms" className="text-gray-400 hover:text-white transition-colors">
                  Terms & Conditions
                </ScrollToTopLink>
              </li>
              <li>
                <ScrollToTopLink to="/disclaimer" className="text-gray-400 hover:text-white transition-colors">
                  Disclaimer
                </ScrollToTopLink>
              </li>
              <li>
                <ScrollToTopLink to="/complaints" className="text-gray-400 hover:text-white transition-colors">
                  Complaints
                </ScrollToTopLink>
              </li>
              <li>
                <ScrollToTopLink to="/partners" className="text-gray-400 hover:text-white transition-colors">
                  Partners
                </ScrollToTopLink>
              </li>
              <li>
                <ScrollToTopLink to="/blog" className="text-gray-400 hover:text-white transition-colors">
                  Blog
                </ScrollToTopLink>
              </li>
              <li>
                <ScrollToTopLink to="/portfolio" className="text-gray-400 hover:text-white transition-colors">
                  Portfolio
                </ScrollToTopLink>
              </li>
            </ul>
          </div>
          
          <div>
            <h4 className="text-lg font-semibold mb-4">Connect With Us</h4>
            <div className="flex space-x-4">
              <a
                href="https://www.instagram.com/corporatesolutions_group/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-400 hover:text-white transition-colors"
              >
                <Instagram className="h-6 w-6" />
              </a>
              <a
                href="https://www.linkedin.com/groups/13125567/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-400 hover:text-white transition-colors"
              >
                <Linkedin className="h-6 w-6" />
              </a>
              <a
                href="https://www.facebook.com/profile.php?id=61559919670733"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-400 hover:text-white transition-colors"
              >
                <Facebook className="h-6 w-6" />
              </a>
            </div>
            <div className="mt-4">
              <p className="text-gray-400">Contact us:</p>
              <ContactLink
                type="email"
                value="info@corporatesolutions.be"
                className="text-gray-400 hover:text-white transition-colors block"
              />
              <ContactLink
                type="phone"
                value="+32474563053"
                className="text-gray-400 hover:text-white transition-colors block mt-2"
              >
                Phone: +32 474 56 30 53 (WhatsApp)
              </ContactLink>
            </div>
          </div>
        </div>
        
        <div className="mt-16 pt-8 border-t border-gray-800">
          <p className="text-center text-gray-400">
            © {new Date().getFullYear()} Corporate Solutions Group. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
}