import React from 'react';
import { Globe, Share2, Lightbulb } from 'lucide-react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export function Services() {
  const { t } = useTranslation();
  const [ref, inView] = useInView({
    triggerOnce: false,
    threshold: 0.1
  });

  const cardVariants = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    hover: { 
      scale: 1.03,
      transition: { duration: 0.2, ease: "easeOut" }
    }
  };

  const services = [
    {
      icon: <Globe className="h-8 w-8" />,
      title: "Web Design & Development",
      description: "Custom websites reflecting your brand identity with user-friendly, conversion-optimized designs.",
    },
    {
      icon: <Share2 className="h-8 w-8" />,
      title: "Social Media Marketing",
      description: "Build your presence on platforms like Instagram, Facebook, and LinkedIn with engagement-driven strategies.",
    },
    {
      icon: <Lightbulb className="h-8 w-8" />,
      title: "Digital Strategy & Automation",
      description: "Streamline your online processes and maximize efficiency with cutting-edge tools and strategies.",
    }
  ];

  return (
    <div className="py-24 bg-white" ref={ref}>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 gap-12 lg:grid-cols-3">
          {services.map((service, index) => (
            <Link to="/contact" key={index}>
              <motion.div
                variants={cardVariants}
                initial="initial"
                animate={inView ? "animate" : "initial"}
                whileHover="hover"
                transition={{ duration: 0.4, delay: index * 0.1 }}
                className="relative group bg-white p-8 rounded-2xl border border-gray-100 shadow-sm cursor-pointer"
              >
                <motion.div 
                  className="text-emerald-500 mb-5"
                  whileHover={{ scale: 1.1 }}
                  transition={{ duration: 0.2 }}
                >
                  {service.icon}
                </motion.div>
                <h3 className="text-xl font-semibold text-gray-900 mb-3">
                  {service.title}
                </h3>
                <p className="text-gray-600">{service.description}</p>
                <motion.span
                  className="inline-flex items-center text-black font-medium mt-4"
                  whileHover={{ x: 5 }}
                  transition={{ duration: 0.2 }}
                >
                  Learn More
                </motion.span>
              </motion.div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}