import React, { useEffect, useRef } from 'react';
import { ArrowLeftRight } from 'lucide-react';

export function ImageComparison() {
  const [position, setPosition] = React.useState(50);
  const containerRef = useRef<HTMLDivElement>(null);
  const isDragging = useRef(false);

  const handleMove = (clientX: number) => {
    if (!containerRef.current) return;
    
    const rect = containerRef.current.getBoundingClientRect();
    const x = Math.min(Math.max(0, clientX - rect.left), rect.width);
    const newPosition = (x / rect.width) * 100;
    setPosition(newPosition);
  };

  const handleMouseDown = (e: React.MouseEvent) => {
    isDragging.current = true;
    handleMove(e.clientX);
  };

  const handleMouseMove = (e: React.MouseEvent) => {
    if (!isDragging.current) return;
    handleMove(e.clientX);
  };

  const handleMouseUp = () => {
    isDragging.current = false;
  };

  const handleTouchStart = (e: React.TouchEvent) => {
    isDragging.current = true;
    handleMove(e.touches[0].clientX);
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    if (!isDragging.current) return;
    handleMove(e.touches[0].clientX);
  };

  const handleTouchEnd = () => {
    isDragging.current = false;
  };

  useEffect(() => {
    window.addEventListener('mouseup', handleMouseUp);
    window.addEventListener('touchend', handleTouchEnd);
    
    return () => {
      window.removeEventListener('mouseup', handleMouseUp);
      window.removeEventListener('touchend', handleTouchEnd);
    };
  }, []);

  return (
    <div 
      ref={containerRef}
      className="relative w-full h-[400px] select-none cursor-col-resize overflow-hidden rounded-2xl"
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
    >
      <div className="absolute inset-0 bg-black">
        <div className="flex items-center justify-center h-full">
          <h2 className="logo-text text-6xl text-white">
            Corporate<br />Solutions<br />Group.
          </h2>
        </div>
      </div>

      <div 
        className="absolute inset-0 bg-white"
        style={{ clipPath: `inset(0 ${100 - position}% 0 0)` }}
      >
        <div className="flex items-center justify-center h-full">
          <h2 className="logo-text text-6xl text-black">
            Corporate<br />Solutions<br />Group.
          </h2>
        </div>
      </div>

      <div 
        className="absolute top-0 bottom-0 w-0.5 bg-gray-400 cursor-col-resize z-10"
        style={{ left: `${position}%`, transform: 'translateX(-50%)' }}
      >
        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white rounded-full p-2 shadow-lg">
          <ArrowLeftRight className="w-6 h-6 text-black" />
        </div>
      </div>
    </div>
  );
}