import React, { Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Header } from './components/layout/Header';
import { Footer } from './components/layout/Footer';
import { Home } from './pages/Home';
import { NotFound } from './pages/NotFound';
import { AuthProvider } from './contexts/AuthContext';
import { useScrollToTop } from './hooks/useScrollToTop';

// Lazy load other pages
const Services = React.lazy(() => import('./pages/Services').then(module => ({ default: module.Services })));
const About = React.lazy(() => import('./pages/About').then(module => ({ default: module.About })));
const Contact = React.lazy(() => import('./pages/Contact').then(module => ({ default: module.Contact })));
const Portfolio = React.lazy(() => import('./pages/Portfolio').then(module => ({ default: module.Portfolio })));
const Blog = React.lazy(() => import('./pages/Blog').then(module => ({ default: module.Blog })));
const BlogPost = React.lazy(() => import('./pages/BlogPost').then(module => ({ default: module.BlogPost })));
const AdminDashboard = React.lazy(() => import('./pages/admin/Dashboard').then(module => ({ default: module.AdminDashboard })));
const AdminLogin = React.lazy(() => import('./pages/admin/Login').then(module => ({ default: module.AdminLogin })));
const BlogManagement = React.lazy(() => import('./pages/admin/BlogManagement').then(module => ({ default: module.BlogManagement })));
const PrivacyPolicy = React.lazy(() => import('./pages/legal/PrivacyPolicy').then(module => ({ default: module.PrivacyPolicy })));
const Terms = React.lazy(() => import('./pages/legal/Terms').then(module => ({ default: module.Terms })));
const Disclaimer = React.lazy(() => import('./pages/legal/Disclaimer').then(module => ({ default: module.Disclaimer })));
const Complaints = React.lazy(() => import('./pages/legal/Complaints').then(module => ({ default: module.Complaints })));
const Partners = React.lazy(() => import('./pages/legal/Partners').then(module => ({ default: module.Partners })));
const Partner = React.lazy(() => import('./pages/Partner').then(module => ({ default: module.Partner })));
const Affiliate = React.lazy(() => import('./pages/Affiliate').then(module => ({ default: module.Affiliate })));
const AffiliateLogin = React.lazy(() => import('./pages/affiliate/Login').then(module => ({ default: module.AffiliateLogin })));
const AffiliateDashboard = React.lazy(() => import('./pages/affiliate/Dashboard').then(module => ({ default: module.AffiliateDashboard })));

function ScrollToTop() {
  useScrollToTop();
  return null;
}

// Loading component
function PageLoader() {
  return (
    <div className="min-h-screen flex items-center justify-center">
      <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-black"></div>
    </div>
  );
}

export default function App() {
  return (
    <AuthProvider>
      <Router>
        <ScrollToTop />
        <div className="min-h-screen bg-white flex flex-col">
          <Header />
          <main className="flex-grow">
            <Suspense fallback={<PageLoader />}>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/services" element={<Services />} />
                <Route path="/about" element={<About />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/portfolio" element={<Portfolio />} />
                <Route path="/blog" element={<Blog />} />
                <Route path="/blog/:slug" element={<BlogPost />} />
                <Route path="/admin" element={<AdminDashboard />} />
                <Route path="/admin/login" element={<AdminLogin />} />
                <Route path="/admin/blog" element={<BlogManagement />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/terms" element={<Terms />} />
                <Route path="/disclaimer" element={<Disclaimer />} />
                <Route path="/complaints" element={<Complaints />} />
                <Route path="/partners" element={<Partners />} />
                <Route path="/partner" element={<Partner />} />
                <Route path="/affiliate" element={<Affiliate />} />
                <Route path="/affiliate/login" element={<AffiliateLogin />} />
                <Route path="/affiliate/dashboard" element={<AffiliateDashboard />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </Suspense>
          </main>
          <Footer />
        </div>
      </Router>
    </AuthProvider>
  );
}