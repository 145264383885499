import React from 'react';
import { Button } from '../common/Button';
import { useTranslation } from 'react-i18next';

export function CTASection() {
  const { t } = useTranslation();

  return (
    <div className="bg-black">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <div className="bg-emerald-500 rounded-2xl shadow-xl overflow-hidden">
          <div className="pt-16 pb-12 px-6 sm:pt-20 sm:px-16 lg:py-20 lg:pr-0 xl:px-20">
            <div className="lg:self-center">
              <h2 className="text-3xl font-bold text-white sm:text-4xl">
                <span className="block">{t('cta.ready')}</span>
                <span className="block">{t('cta.create')}</span>
              </h2>
              <p className="mt-4 text-lg leading-6 text-emerald-50">
                {t('cta.getInTouch')}
              </p>
              <Button
                href="/contact"
                variant="secondary"
                className="mt-8"
              >
                {t('cta.getStarted')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}